let API_KEY = ['AIzaSyB25youL9WVINWAgLi1uWRkNWkKGKxB-AY'];
 
 export async function translateTextWithGoogleAPI(text, source,targetLanguage) {
    
    // console.log("Text for translated in survey form!",text)
    // console.log("Source lang:",source)
    //  if source is en, return original text
    if (targetLanguage === 'en') {
      return text;
    }
    else{
      try {
        let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
        url += `&q=${encodeURI(text)}`;
        url += `&source=${source}`;
        url += `&target=${targetLanguage}`;

        return fetch(url, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        })
          .then(res => res.json())
          .then((response) => {
            console.log("response from Google:", response);
            return response.data.translations[0].translatedText;
          })
      } catch (error) {
        console.error('Translation error:', error);
        return text; // Return the original text in case of an error
      }
    }   
  }