import React from 'react';
import RouteComponent from './Routes';
import { GlobalProvider } from './GlobalContext';
function App() {
  return (
    <GlobalProvider>
      <RouteComponent/>
    </GlobalProvider>
  );
}

export default App;
