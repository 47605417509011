import FadeLoader  from "react-spinners/FadeLoader";

const override = {
  display: "flex",
  margin: "0 auto",
  borderColor: "#36d7b7",
};

function LoadingSpinner() {
    return (
        <div
        className="sweet-loading"
        style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }}
        >
        <FadeLoader 
            loading={true}
            cssOverride={override}
            size={75}
            color="#36d7b7"
            aria-label="Loading Spinner"
            data-testid="loader"
        />
        </div>
    );
}

export default LoadingSpinner;
