import React,{useState,useEffect} from 'react';
import SelectSchoolAgencyStyle from './SelectSchoolwithAgency.module.css';
import { Container ,Row, Col,Card, Button,Dropdown } from 'react-bootstrap';
import Card1 from './../../Assets/SelectSchoolScreen/Asset 18.webp';
import Card2 from './../../Assets/SelectSchoolScreen/Asset 6.webp';
import AgencyService from '../../Services/Api/AgencyService';
import {translateContent} from '../../Component/GoogleTranslation/Maptranslation'
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { useGlobalContext } from '../../GlobalContext';

function SelectSchoolwithAgency() {
    
    const navigate =useNavigate();
    const [roleid,setRoleId]=useState('');
    const [AgencyDropwdownoptions,setAgencyDropwdownoptions]=useState('')
    const [SchoolDropdownoptions,setSchoolDropdownoptions]=useState('')
    const [selectedAgencyOption,setSelectedAgencyOption]=useState('')
    const [selectedSchoolOption,setSelectedSchoolOption]=useState('')
    const [AgencyFetchData,setAgencyFetchData]=useState([]);
    const [AgencyselectedOption, setAgencySelectedOption] = useState('');
    const [SchoolselectedOption, setSchoolselectedOption] = useState('');
    const searchText='';
    const [schoolid,setSchoolId]=useState('');
    const [errorMessages,setMessages]=useState('');
    const [AlertInfo,setAlertInfo]=useState('');

    const selectedLanguage=(window.sessionStorage.getItem("SelectedLanguage") || 'en');

    const [translations, setTranslations] = useState({});

    const { globalUrl } = useGlobalContext();

    useEffect(() => {
      axios.defaults.baseURL = globalUrl;
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('token')}`;
      axios.defaults.headers.common["Content-Type"] = "application/json";
    }, [globalUrl]);

    useEffect(() => {
    async function loadTranslations() {
        const elementsToTranslate = [
        'SELECT YOUR SCHOOL',
        'LOGOUT',
        'Add School',
        'Manage School Coloring',
        'Add Map',
        'Select Agency',
        'Select School',
        'SUBMIT',
        'Manage Survey Form',
        'CHANGE PASSWORD'
        ];

        const source = selectedLanguage === 'es' ? 'en' : 'es';

        try {
        const translatedTexts = await translateContent(elementsToTranslate, source, selectedLanguage);
        setTranslations(translatedTexts);
        } catch (error) {
        console.error('Translation error:', error);
        // Handle the error as needed
        }
    }

        loadTranslations();
    }, [selectedLanguage]);
    
    useEffect(() => {
      getLocalStorage();
    }, []);
    
    useEffect(() => {
      if (selectedAgencyOption) {
        fetchSchoolsList(selectedAgencyOption);
      }
    }, [selectedAgencyOption]);

    useEffect(() => {
      ////console.log("Agency Fetch of School list:", AgencyFetchData);
    }, [AgencyFetchData]);

    useEffect(() => {
      ////console.log("In use effect for mapping schools list")
      ////console.log("Agency fetch Data:",AgencyFetchData)
      const schoolsArray = Object.values(AgencyFetchData); // Convert object to array

      if (schoolsArray.length > 0) {
      let schoolsOption=[];
      if(schoolsArray.length>0){
        const schoolsOption = schoolsArray.map((item) => {
          return{
            value:item.id,
            label:item.school_name + '-'  + item.school_type + ' School'
          }
        })
        setSchoolDropdownoptions(schoolsOption);
        ////console.log("Schools Dropdown Option",schoolsOption)
      }
      else{
        schoolsOption=[{
          value:schoolsArray.id, //Value represents Agency ID
          label: schoolsArray.school_name + '-'  + schoolsArray.school_type + ' School'
        }]
        setSchoolDropdownoptions(schoolsOption);
        ////console.log("Schools Dropdown Option",schoolsOption)
      }
      }
    }, [AgencyFetchData]);


    // Get the data from local storage and store in setAgencyData state
    const getLocalStorage = () => {
      const TokenDetails = JSON.parse(localStorage.getItem('UserInfo'));
      
      if (TokenDetails) {
        try {
          ////console.log("Agency Code in local Storage", TokenDetails);
          const roleID=TokenDetails.role_id;
          setRoleId(roleID);

        if(roleID===3 || roleID===4){ //If teacher and agency
          
          //Set the dropdown option value and id of backend data
          const mappedOptions={
            value:TokenDetails.agency_id, //Value represents Agency ID
            label:TokenDetails.agency.name     //Agency Name represent Agency Name
          }
          ////console.log('mappedOptions',mappedOptions)
          setAgencyDropwdownoptions(mappedOptions);
        }
        
        else if(roleID===1 || roleID===2) //If the admin or superadmin
        {
          AgencyService.getAgencies().then(
            (data)=>{
              ////console.log("Data from Api of Agencies list", data);
              const mappedOptions=data.map((item)=>{ //To deal with multiple options
                return{
                  value:item.id,
                  label:item.name
                }
              })
              ////console.log('mappedOptions of Agencies list',mappedOptions)
              setAgencyDropwdownoptions(mappedOptions);
            }
          )
          
          }
        } catch (error) {
          console.error('Error in getting Token data:', error);
        }
      }
    }; //End Local Storage Method

    const fetchSchoolsList = (code) => {
      ////console.log("Before fetching School list");
      AgencyService.getAgencySchools(code)
        .then((data) => {
          ////console.log("Data from Api of Schools list", data);
          setAgencyFetchData(data); // Store the fetched data in the state
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            ////console.log("401 error");
            navigate('/Error'); // Navigate to your error page
          }
        });
    };


  
    const handleAgencyOptionSelect = (option) => {
        setAgencySelectedOption(option);
        ////console.log("Selected Agency Option value:",option.value)
        setSelectedAgencyOption(option.value)
    };
    const handleSchoolOptionSelect = (option) => {
      setSchoolselectedOption(option);
      ////console.log("Selected School Option value:",option.value)
      setSelectedSchoolOption(option.value)
      setSchoolId(option.value)
    };

    let filteredOptions = [];

    if (Array.isArray(AgencyDropwdownoptions)) {
        // Handling multiple options
        filteredOptions = AgencyDropwdownoptions.filter((option) =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
      );
    } else if (AgencyDropwdownoptions) {
        // Handling single option
        filteredOptions = [AgencyDropwdownoptions]; // Convert the single option to an array
    }

    let SchoolOptions=[];
    if (Array.isArray(SchoolDropdownoptions)) {
      //Handling multiple options
      SchoolOptions = SchoolDropdownoptions.filter((option) =>
          option.label.toLowerCase().includes(searchText.toLowerCase())
      );

    } else if (SchoolDropdownoptions) {
        // Handling single option 
        SchoolOptions = [SchoolDropdownoptions]; // Convert the single option to an array
    }
    const logout = (e) => {
      e.preventDefault();
      ////console.log("Role ID in logout Function",roleid)
      switch(roleid)
        {
            case 1:
                localStorage.removeItem('token');
                window.location.href= '/admin-login';
                break;
            case 2:
              localStorage.removeItem('token');  
              window.location.href='/admin-login';
              break;
            case 3:
                localStorage.removeItem('token');
                window.location.href='/educator-signin';
              break;
            case 4:
                localStorage.removeItem('token');
                window.location.href='/agency-signin';
              break;
            default:
                localStorage.removeItem('token');
                window.location.href='/admin-login';
        
        }
    }
    const ApiCall=async(e)=>{
      if(selectedAgencyOption === ''){
        setMessages(["Please select Agency!"]);
        setAlertInfo(true);
        return;
      }
      else if(selectedSchoolOption === ''){
        setMessages(["Please select School!"]);
        setAlertInfo(true);
        return;
      } 
      e.preventDefault();
      ////console.log("Form Data values before post request:",schoolid)
    try{
      const response=await axios({
        method: 'get',
        url: 'api/surveyData/',
          params: {
            school_id: schoolid
          }
      })
      
        if(response.data.status === 'error'){
          ////console.log("Error in the data:",response.data)
          ////console.log("Response Status:",response.data.status)
        }
        else{
          ////console.log("Response",response.data)
          ////console.log("Data posted successfully")
          localStorage.removeItem('FromDate')
          localStorage.removeItem('toDate')
          const data=response.data;
          navigate('/surveyreport', { state: data });
        }
      }catch(error){
        ////console.log("Eroror:",error)
        if (error && error.response && error.response.status === 401) {
          ////console.log("401 error");
          navigate('/Error'); // Navigate to your error page
        }
      }
    }
  return (
    <div className={`${SelectSchoolAgencyStyle.container} container-fluid`}>
      <Container fluid>
        <Container>
          <Row className="py-3 text-center">
            <Col xs={12} md={6} className="d-flex justify-content-start">
              <Button
                className={`${SelectSchoolAgencyStyle.logoutbtn} shadow-lg`}
                onClick={(e) => logout(e)}
              >
                {translations['LOGOUT']}
              </Button>
              <Button
                className={`${SelectSchoolAgencyStyle.logoutbtn} shadow-lg ms-2`}
                onClick={(e) => navigate('/change-password')}
              >
                {translations['CHANGE PASSWORD']}
              </Button>
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-end align-items-center mt-2 mt-md-0">
               {/* If user is superadmin and admin, then Display this button */}
               {(roleid === 1 || roleid === 2) && (
                <Button
                  className={`${SelectSchoolAgencyStyle.schoolbtn} shadow-lg mx-2`}
                  href="/Colorschoolslist"
                >
                  {translations['Manage School Coloring']}
                </Button>
              )}
              {/* If user is superadmin, then Display this button */}
              {roleid === 1 && (
                <Button
                  className={`${SelectSchoolAgencyStyle.schoolbtn} shadow-lg ms-0`}
                  href="/schoolregistration"
                >
                  {translations['Add School']}
                </Button>
              )}
              {(roleid === 1 || roleid === 2) && (
                <Button
                  className={`${SelectSchoolAgencyStyle.schoolbtn} shadow-lg ms-2`}
                  href="/managesurveyform"
                >
                  {translations['Manage Survey Form']}
                </Button>
              )}
            </Col>
          </Row> 
        </Container>
        <Row>
          <Col className="text-center pb-2">
            <h3 className={SelectSchoolAgencyStyle.heading}>{translations['SELECT YOUR SCHOOL']}</h3>
          </Col>
        </Row>
        <Row className="pt-5 mt-4 justify-content-center">
          <Col className="d-flex justify-content-center" xs={12} sm={6} lg={4}>
            <Card style={{ width: '22rem'}} className={`${SelectSchoolAgencyStyle.card} shadow-lg  bg-transparent mb-5 mt-5 mt-sm-0`}>
                    <div className={`${SelectSchoolAgencyStyle.cardimage} py-3`}>
                        <img src={Card1} alt="Logo" className={`${SelectSchoolAgencyStyle.imageresponsive} image-fluid`} />
                    </div>
              <Card.Body  className={`${SelectSchoolAgencyStyle.cardbody} text-center pt-5 pb-5`}>
                <Dropdown  className={`${SelectSchoolAgencyStyle.dropdownblock} dropdown-block full-width-dropdown`}>
                <Dropdown.Toggle className={`${SelectSchoolAgencyStyle.dropdowntogglelarge} dropdown-toggle-large`}> 
                {AgencyselectedOption ? AgencyselectedOption.label : translations['Select Agency']}
                </Dropdown.Toggle>
                    <Dropdown.Menu className={`${SelectSchoolAgencyStyle.dropdownmenu} dropdown-menu dropdown-menu-scrollable`}> 
                    {filteredOptions.map((option) => (
                        <Dropdown.Item  className={`${SelectSchoolAgencyStyle.dropdownItem}`} key={option.value} onClick={() => handleAgencyOptionSelect(option)}>
                        {option.label}
                        </Dropdown.Item>
                    ))}
                    </Dropdown.Menu>
                </Dropdown>
              </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex justify-content-center" xs={12} sm={6} lg={4}>
              <Card style={{ width: '22rem'}}  className={`${SelectSchoolAgencyStyle.card} shadow-lg bg-transparent mb-5 card mt-3 mt-sm-0 mt-md-0`}>
                <div className={`${SelectSchoolAgencyStyle.cardimage} py-3`}>
                  <img src={Card2} alt="Logo" className={`${SelectSchoolAgencyStyle.imageresponsive} image-fluid`} />
                </div>
                <Card.Body  className={`${SelectSchoolAgencyStyle.cardbody} text-center pt-5 pb-5`}>
                <Dropdown className={`${SelectSchoolAgencyStyle.dropdownblock} dropdown-block full-width-dropdown`}>
              <Dropdown.Toggle className={`${SelectSchoolAgencyStyle.dropdowntogglelarge} dropdown-toggle-large`}>
                {SchoolselectedOption ? SchoolselectedOption.label : translations['Select School']}
              </Dropdown.Toggle>
              <Dropdown.Menu className={`${SelectSchoolAgencyStyle.dropdownmenu} dropdown-menu dropdown-menu-scrollable`}>
                {SchoolOptions.map((option) => (
                  <Dropdown.Item className={`${SelectSchoolAgencyStyle.dropdownItem}`} key={option.value} onClick={() => handleSchoolOptionSelect(option)}>
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

                </Card.Body>
              </Card>
            </Col>
        </Row>
        <Row>
          <Col>
            <div className='text-center'>
              {AlertInfo ? (
                <Alert
                  variant={Object.keys(errorMessages).length > 0 ? 'danger' : 'success'}
                  show={Object.keys(errorMessages).length > 0}
                  className="mx-auto d-inline-block text-center" // Center align text
                  style={{ paddingLeft: '0px', paddingRight: '30px' }} // Equal padding on both sides
                >
                  <p>
                    {Object.keys(errorMessages).length > 0 && (
                      <ul>
                        {Object.entries(errorMessages).map(([field, messages]) => (
                          <div key={field}>
                            <h4 style={{ fontSize: "16px", padding: "0px", fontWeight: "400" }}>
                              {errorMessages}
                            </h4>
                          </div>
                        ))}
                      </ul>
                    )}
                  </p>
                </Alert>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className='py-3 pb-4 text-center'>
          <Col>
            <Button className={`${SelectSchoolAgencyStyle.instructionbtn} shadow-lg`} onClick={ApiCall}>
              {translations['SUBMIT']}
            </Button>
          </Col>
        </Row> 
      </Container>
    </div>
  );
}

export default SelectSchoolwithAgency;
