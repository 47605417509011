
let API_KEY = ['AIzaSyB25youL9WVINWAgLi1uWRkNWkKGKxB-AY'];

export async function translateContent(elementsToTranslate, source, target) {
  //If source is en, return original text
  // console.log("Source lang:",source)
  if (target === 'en') {
    const translationMap = {};
    elementsToTranslate.forEach((text) => {
      translationMap[text] = text; // Use the original text
    });

    return translationMap;
  }
  else
  {
    try {
      const translations = await Promise.all(
        elementsToTranslate.map((text) => {
          let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
          url += `&q=${encodeURI(text)}`;
          url += `&source=${source}`;
          url += `&target=${target}`;

          return fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          })
            .then((res) => res.json())
            .then((response) => {
              console.log('response from Google:', response);
              return response.data.translations[0].translatedText;
            })
            .catch((error) => {
              console.log('There was an error with the translation request:', error);
              throw error;
            });
        })
      );

      const translationMap = {};
      elementsToTranslate.forEach((text, index) => {
        translationMap[text] = translations[index];
      });

      return translationMap;
    } catch (error) {
      console.error('There was an error with the translation request:', error);

      // Return the original text in case of any error
      const translationMap = {};
      elementsToTranslate.forEach((text) => {
        translationMap[text] = text; // Use the original text
      });
      return translationMap;
    }
  }
}