import React, { useState,useEffect, useRef } from 'react';
import './style.css'
import VideoIns from "./../../Assets/School video 4_2.mp4"
const VideoPlayer = () => {
    const videoRef = useRef(null);
    const circlePlayButtonRef = useRef(null);
  
    const togglePlay = () => {
      const video = videoRef.current;
      if (video.paused || video.ended) {
        video.play();
        video.volume = 0.2;
      
      } else {
        video.pause();
      }
    };
  
    useEffect(() => {
      const video = videoRef.current;
      const circlePlayButton = circlePlayButtonRef.current;
  
      if (video && circlePlayButton) {
        circlePlayButton.addEventListener("click", togglePlay);
  
        video.addEventListener("playing", () => {
          circlePlayButton.style.opacity = 0;
        });
  
        video.addEventListener("pause", () => {
          circlePlayButton.style.opacity = 1;
        });
      }
    }, []); 
  


  return (
    <div id='instruct-video'>
        
        <div className="video-wrapper" style={{ paddingTop: '5%' }}>
      <div className="video-container" id="video-container">
        <video controls id="video" preload="metadata" ref={videoRef}>
          <source src={VideoIns} type="video/mp4" />
        </video>

        <div className="play-button-wrapper" ref={circlePlayButtonRef}>
          <div title="Play video" className="play-gif" id="circle-play-b">
            {/* SVG Play Button */}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
              <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default VideoPlayer;
