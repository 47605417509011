import React,{useState,useEffect} from 'react';
import { Dropdown,Container, Row, Col,FormControl, Card, Form, Button } from 'react-bootstrap';
import logo from './../../Assets/Login_SignUp/Logo-01.webp';
import SchoolRegistrationStyles from './editSchool.module.css'; 
import AgencyService from '../../Services/Api/AgencyService';
import Alert from 'react-bootstrap/Alert'
import axios from 'axios';
import { useParams,useNavigate } from 'react-router-dom';
import {translateContent} from '../../Component/GoogleTranslation/Maptranslation'
import { translateErrors } from '../../Component/GoogleTranslation/ErrorTranslation';
import { useGlobalContext } from '../../GlobalContext';

function EditSchoolRegistration() {
    const { schoolid } = useParams();
    const navigate=useNavigate();
    ////console.log("Params:",schoolid)
    const [AgencyselectedOption, setAgencySelectedOption] = useState('');
    const [schoolTypeOption,setSchoolTypeoption]=useState('')
    const [searchText, setSearchText] = useState('');
    const [agencyOptions,setAgencyOptions]=useState([])
    const [errorMessages, setMessages] = useState({});
    const [AlertInfo,setAlertInfo]=useState(false)
    const [successMessage,setSuccessMessage]=useState();
    const { globalUrl } = useGlobalContext();

    useEffect(()=>{
        axios.defaults.baseURL = globalUrl;
        axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`;
        axios.defaults.headers.post["Content-Type"] = "application/json";
    },[globalUrl])
    
    const [formData, setFormData] = useState({
        school_name: '',
        school_type: '',
        agency_id: '',
        code:'',
        status:'',
    });

    const [isChecked, setIsChecked] = useState('');
    
    // Update isChecked whenever formData.status changes
    useEffect(() => {
        setIsChecked(formData.status === 'active');
    }, [formData.status]);

     // Function to handle switch toggle
    const handleSwitchToggle = () => {
        const newStatus = isChecked ? 'inactive' : 'active';
        setFormData({ ...formData, status: newStatus });
    };
    const SchoolTypeOptions=[
        { value: 'elementary', label: 'Elementary School' },
        { value: 'middle', label: 'Middle School' },
        { value: 'high', label: 'High School' },
        { value: 'community', label: 'Community School' },
    ]
    const selectedLanguage=(window.sessionStorage.getItem("SelectedLanguage") || 'en');

    const [translations, setTranslations] = useState({});

    useEffect(() => {
    async function loadTranslations() {
        const elementsToTranslate = [
            'EDIT SCHOOL REGISTRATION',
            'School Name',
            'Enter Name',
            'Select School Type',
            'Select Agency',
            'Select School',
            'Search options...',
            'School Code',
            'Enter Code',
            'Status',
            'ADD SCHOOL',
            'UPDATE SCHOOL',
            'Schools List',
            'Add Map',
        ];

        const source = selectedLanguage === 'es' ? 'en' : 'es';

        try {
        const translatedTexts = await translateContent(elementsToTranslate, source, selectedLanguage);
        setTranslations(translatedTexts);
        } catch (error) {
        //console.error('Translation error:', error);
        // Handle the error as needed
        }
    }
        loadTranslations();
    }, [selectedLanguage]);


    // Fetch agency options
    const fetchAgencies = async () => {
        try {
            const agencyResponse = await AgencyService.getAgencies();
            ////console.log("All agencies list:", agencyResponse);

            const mappedOptions = agencyResponse.map(item => ({
                value: item.id,
                label: item.name,
            }));
            setAgencyOptions(mappedOptions);
        } catch (error) {
            ////console.log("Error fetching agencies:", error);
            if (error && error.response && error.response.status === 401) {
                ////console.log("401 error");
                navigate('/Error'); // Navigate to your error page
            }
        }
    };

    useEffect(()=>{
        fetchAgencies();
    },[])
    
      
    useEffect(() => {
        // Fetch single school record
        const fetchSingleSchool = async () => {
            try {
                const response = await axios.get(`https://ksapbackend.hotspotmapky.com/api/school/${schoolid}`);
                ////console.log("Response from backend of Single Record", response.data);
                ////console.log("Error in fetching:",response.data.error)
                setFormData({
                    school_name: response.data.school_name,
                    school_type: response.data.school_type,
                    agency_id: response.data.agency.id,
                    code: response.data.school_code,
                    status: response.data.status,
                });
    
                const selectedAgencyOption = agencyOptions.find(
                    (option) => option.value === response.data.agency.id
                );
                setAgencySelectedOption(selectedAgencyOption);
    
                const selectedSchoolTypeOption = SchoolTypeOptions.find(
                    (option) => option.value === response.data.school_type
                );
    
                setSchoolTypeoption(selectedSchoolTypeOption);
            } catch (error) {
                ////console.log("Error on fetching single school list", error);
                if (error && error.response && error.response.status === 401) {
                    ////console.log("401 error");
                    navigate('/Error'); // Navigate to your error page
                }
            }
        };
    
        fetchSingleSchool();
    }, [schoolid, agencyOptions]);
    
    
    const handleSubmit=async(e)=>{
        e.preventDefault();

        ////console.log("Before Posting Form Data:",formData)
        const authToken = localStorage.getItem('token'); // Get the token from local storage
        const axiosInstance = axios.create({
            baseURL: globalUrl, // Your API URL
            headers: {
                'Authorization': `Bearer ${authToken}`, // Set the authorization header
                'Content-Type': 'application/json', // Set other headers if needed
            },
        })
        try{
        const response=await axiosInstance.put(`/api/school/${schoolid}`, formData)

            if(response.data.status === 'error') /*For Handling Error */
            {
                const source = selectedLanguage === 'es' ? 'en' : 'es';
                ////console.log("Response Status:", response.data.status);

                // Extract and translate each error message in the object
                const translatedErrors = await Promise.all(
                    Object.values(response.data.message).map(async (errorArray) => {
                    const translatedErrorArray = await Promise.all(
                        errorArray.map(async (error) => {
                        try {
                            const translatedError = await translateErrors(
                            error,
                            source,
                            selectedLanguage
                            );
                            return translatedError;
                        } catch (error) {
                            //console.error('Error translating error message:', error);
                            return error; // Fallback to the original error message
                        }
                        })
                    );
                    return translatedErrorArray.join(', '); // Join translated errors into a string
                    })
                );

                setMessages(translatedErrors);
                ////console.log("Translated Errors:", translatedErrors);
                setAlertInfo(true);
            }
            else{ //If No error status
                const source = selectedLanguage === 'es' ? 'en' : 'es';
                const translatedSuccessMessage = await translateErrors(
                    response.data.message,
                    source,
                    selectedLanguage    
                );
                setSuccessMessage(translatedSuccessMessage);
                setAlertInfo(true)
            }
        }
        catch(error){
            if (error.response) {
                const source = selectedLanguage === 'es' ? 'en' : 'es';

                // Extract and translate each error message in the object
               const translatedErrors = await Promise.all(
                   Object.values(error.response.data.message).map(async (errorArray) => {
                   const translatedErrorArray = await Promise.all(
                       errorArray.map(async (error) => {
                       try {
                           const translatedError = await translateErrors(
                           error,
                           source,
                           selectedLanguage
                           );
                           return translatedError;
                       } catch (error) {
                           //console.error('Error translating error message:', error);
                           return error; // Fallback to the original error message
                       }
                       })
                   );
                   return translatedErrorArray.join(', '); // Join translated errors into a string
                   })
               );
               setMessages([translatedErrors]);
               setAlertInfo(true)
            }
        }
    }

    const handleSchoolOptionSelect = (option) => {
        setSchoolTypeoption(option);
        setFormData((prevFormData) => ({
            ...prevFormData,
            school_type: option.value, //Saving the selected option in School type field 
        }));
    };

    const handleAgencyOptionSelect = (option) => {
        setAgencySelectedOption(option);
        setFormData((prevFormData) => ({
            ...prevFormData,
            agency_id: option.value, //Saving the selected option in agency field 
        }));
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const AgencyfilteredOptions = agencyOptions.filter((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    return (
    <div className='signup-page mb-5 '>
      <Container className='mb-5 '>
        <Row className='justify-content-center align-items-center vh-100 ms-md-5 me-md-5 mb-5'>
          <Col xs={12} sm={6} md={7} lg={7}>
            <Card className={`${SchoolRegistrationStyles.customcard} shadow-lg`}>
            <div  className={`${SchoolRegistrationStyles.cardheader} card-header`}>
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-end pt-5 img-fluid">
                            <img src={logo} alt="Logo" className={`${SchoolRegistrationStyles.logoimage}`}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className='text-center pt-5 pb-5'> {/* Add pt-4 class for top padding */}
                            <h2 className={`${SchoolRegistrationStyles.heading} mt-2`}>{translations['EDIT SCHOOL REGISTRATION']}</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
            <>
            {AlertInfo ? (
            <Alert variant={Object.keys(errorMessages).length > 0 ? 'danger' : 'success'} show={Object.keys(errorMessages).length > 0 || successMessage !== null}>
            <p>
            {Object.keys(errorMessages).length > 0
                ? (
                <ul>
                    {Object.entries(errorMessages).map(([field, messages]) => (
                    <li key={field}>
                        {/\d/.test(field) ? '' : `${field}: `}{Array.isArray(messages) ? messages.join(', ') : messages}
                    </li>
                    ))}
                </ul>
                ) : (
                    successMessage
                )}
            </p>
            </Alert>
            ) : null}
            </>
            <Card.Body className={`${SchoolRegistrationStyles.cardbodywithbackground} ps-md-5 pe-md-5 pt-3 shadow-lg rounded`}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='name' className={`${SchoolRegistrationStyles.formgroup} form-group`}>
                    <Form.Label>{translations['School Name']}</Form.Label>
                    <Form.Control type='text' placeholder={translations['Enter Name']} onChange={handleChange} name='school_name' value={formData.school_name}/>
                    </Form.Group>
                    <Form.Label>{translations['Select School Type']}</Form.Label>
                    <Dropdown  className={`${SchoolRegistrationStyles.dropdownblock} dropdown-block full-width-dropdown`}>
                    <Dropdown.Toggle className={`${SchoolRegistrationStyles.dropdowntogglelarge} dropdown-toggle-large`}> 
                    {schoolTypeOption ? schoolTypeOption.label : translations['Select School']}
                    </Dropdown.Toggle>
                        <Dropdown.Menu className={`${SchoolRegistrationStyles.dropdownmenu} dropdown-menu dropdown-menu-scrollable`}> 
                        {SchoolTypeOptions.map((option) => (
                            <Dropdown.Item key={option.value} onClick={() => handleSchoolOptionSelect(option)}>
                            {option.label}
                            </Dropdown.Item>
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Form.Label className='pt-2'>{translations['Select Agency']}</Form.Label>
                    <Dropdown  className={`${SchoolRegistrationStyles.dropdownblock} dropdown-block full-width-dropdown`}>
                    <Dropdown.Toggle className={`${SchoolRegistrationStyles.dropdowntogglelarge} dropdown-toggle-large`}> 
                    {AgencyselectedOption ? AgencyselectedOption.label : translations['Select Agency']}
                    </Dropdown.Toggle>
                        <Dropdown.Menu className={`${SchoolRegistrationStyles.dropdownmenu} dropdown-menu dropdown-menu-scrollable`}> 
                        <FormControl
                            type='text'
                            placeholder={translations['Search options...']}
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                        <Dropdown.Divider />
                        {AgencyfilteredOptions.map((option) => (
                            <Dropdown.Item key={option.value} onClick={() => handleAgencyOptionSelect(option)}>
                                {option.label}
                            </Dropdown.Item>
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Form.Group controlId='code' className={`${SchoolRegistrationStyles.formgroup} form-group pt-3`}>
                    <Form.Label>{translations['School Code']}</Form.Label>
                    <Form.Control type='text' placeholder={translations['Enter Code']} onChange={handleChange} name='code' value={formData.code}/>
                    </Form.Group>
                    <Form.Label>
                        {translations['Status']}
                    </Form.Label>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={isChecked}
                        onChange={handleSwitchToggle}
                    />
                   
                    <div className='text-center pt-5'>
                        <Button variant="" className={`${SchoolRegistrationStyles.btnsignup} text-center`} type='submit'>{translations['UPDATE SCHOOL']} </Button>
                    </div>
                    <div className='d-flex justify-content-center pt-5'>
                        <h5 className={`${SchoolRegistrationStyles.loginbtn} py-3`}>
                            <a href='/allschoolslist'>
                                {translations['Schools List']}
                            </a>
                        </h5>
                        <h5 className={`${SchoolRegistrationStyles.loginbtn} py-3`}>
                            <a href='/addschoolmap'>
                                {translations['Add Map']}
                            </a>
                        </h5>
                    </div>
                </Form>
            </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default EditSchoolRegistration;
