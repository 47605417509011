import React,{useState,useEffect,lazy} from 'react';
import { Dropdown,Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import logo from './../../Assets/Login_SignUp/Logo-01.webp';
import Styles from './StudentSurveyForm.module.css'; 
import './Surveyform.css'
import axios from 'axios';
import { useLocation,useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import { translateTextWithGoogleAPI } from './../../Component/GoogleTranslation/SingleTranslation';
import LoadingSpinner from './../../Component/ComponentLoader/LoadingSpinner';
import { useGlobalContext } from '../../GlobalContext';
import {translateContent} from '../../Component/GoogleTranslation/Maptranslation';
//import SurveySection from './SurveySection';
const SurveySection = lazy(() => import('./SurveySection'));


function StudentSurveyForm() {
    const navigate=useNavigate();
    const location = useLocation();
    const data = location.state;
    const localData=localStorage.getItem('studentCode');
    useEffect(()=>{
        if(localData !=='valid'){
            navigate('/student-schoolcode');
        }
    },[])
    useEffect(() => {
        if (!data) {
            navigate('/student-schoolcode');
        } else {
            localStorage.setItem("schoolDataInformation", JSON.stringify(data));
            setSchoolCode(data[0].id);
        }
    }, [data, navigate]);

    const [schoolcode,setSchoolCode]=useState('');
    const [errorMessages, setMessages] = useState({});
    const [AlertInfo,setAlertInfo]=useState(false)
    const [sectionDataArray, setSectionDataArray] = useState([]);

    
    const selectedLanguage= (window.sessionStorage.getItem("SelectedLanguage") || 'en');
    

    const surveyFormObj=JSON.parse(localStorage.getItem('SurveyFormInformation'))

    const gradeid=surveyFormObj?.grade;

    const AnswersLocalStorage=surveyFormObj?.answers;


    useEffect(() => {
        if (gradeid) {

            // Find the corresponding option from the options array
            const initialSelectedGrade = options.find((option) => option.value === gradeid);
            // Set the selectedGrade state with the initial value
            if (initialSelectedGrade) {
                setSelectedGrade(initialSelectedGrade);
            }
        }
    }, [gradeid]);
    
    // Initialize selectedValues based on the answers from local storage
    const [selectedValues, setSelectedValues] = useState(() => {
        if (AnswersLocalStorage) {
        const initialSelectedValues = {};
    
        for (const questionId in AnswersLocalStorage) {
            const optionId = AnswersLocalStorage[questionId].option;
            initialSelectedValues[questionId] = optionId;
        }
    
        return initialSelectedValues;
        }
    
        // If no answers are found in local storage, initialize with an empty object
        return {};
    });

    const { globalUrl } = useGlobalContext();

    useEffect(()=>{
        axios.defaults.baseURL = globalUrl;
    },[globalUrl])

    axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    
    const [loading,setLoading]=useState(false);

    const [translations, setTranslations] = useState({});

  useEffect(() => {
    async function loadTranslations() {
        const elementsToTranslate = [
         'SURVEY FORM',
         'Enter Your Grade',
          'SUBMIT'
        ];

        const source = selectedLanguage === 'es' ? 'en' : 'es';

        try {
        const translatedTexts = await translateContent(elementsToTranslate, source, selectedLanguage);
        setTranslations(translatedTexts);
        } catch (error) {
        //console.error('Translation error:', error);
        // Handle the error as needed
        }
    }

        loadTranslations();
    }, [selectedLanguage]);

    const [commentsData, setCommentsData] = useState([]);

    // Function to update commentsData when a user adds or edits a comment
    const updateComment = (index, text) => {
        setCommentsData((prevCommentsData) => ({
        ...prevCommentsData,
        [index]: {
            ...prevCommentsData[index],
            text,
        },
        }));
    };
  
    // Load comments from local storage when the component mounts
    useEffect(() => {
        if (AnswersLocalStorage) {
            setCommentsData(AnswersLocalStorage);
        }
    }, []);

    const handleSubmit = async() => {
        if (!selectedGrade) {
            try {
                const source = selectedLanguage === 'es' ? 'en' : 'es';
                let Text = await translateTextWithGoogleAPI("Please fill the complete form before submitting", source, selectedLanguage);
                setMessages([Text]);
                setAlertInfo(true)
                ////console.log("Text after translation", Text);
            } catch (error) {
                console.error('Error translating  text:', error);
                return;
            }
            return;
        }
    
        // Check if any required question is not answered
        const isAnyQuestionUnanswered = sectionDataArray.some((sectionData) => {
            const { id } = sectionData;
            const selectedOptionId = selectedValues[id]; // Get selected option ID
            return !selectedOptionId;
        });
    
        if (isAnyQuestionUnanswered) {
            try {
                const source = selectedLanguage === 'es' ? 'en' : 'es';
                let Text = await translateTextWithGoogleAPI("Please answers all the questions before submitting", source, selectedLanguage);
                setMessages([Text]);
                setAlertInfo(true)
                ////console.log("Text after translation", Text);
            } catch (error) {
                console.error('Error translating  text:', error);
                return;
            }
            return;
        }
    
        // Prepare the answers data
        const answersData = {};
        sectionDataArray.forEach((sectionData) => {
        const { id, survey_options } = sectionData;
        const selectedOptionId = selectedValues[id]; // Get selected option ID
        const commentElement = document.getElementById(`comment-${id}`);
        const commentText = commentElement ? commentElement.value.trim() : ''; // Get comment text from textarea

        // Check if the selected option's text is not equal to "Prefer to Define"
        const selectedOption = survey_options.find((option) => option.id === selectedOptionId);
        if (selectedOption && selectedOption.option_text !== "Prefer to Define") {
            // Set the comment value to null or omit it based on your preference such as null
            const selectedOptionData = {
            option: selectedOptionId,
            text: "",
            };
            answersData[id] = selectedOptionData;
        } else if (commentText !== "") {
            // If the selected option is "Prefer to Define" or no option is selected, include the comment data
            const selectedOptionData = {
            option: selectedOptionId,
            text: commentText,
            };
            answersData[id] = selectedOptionData;
        }
        });

        // Prepare the entire payload for the POST request
        const payload = {
            school_id: `${schoolcode}`,
            grade: selectedGrade.value,
            answers: answersData,
        };
        ////console.log("Form Data before posting:", payload);
        localStorage.setItem("SurveyFormInformation",JSON.stringify(payload))
        window.location.href='/addfloorColor';
    };
    
    
    //for fetching the record to show in the survey form
    useEffect(()=>{
        if(schoolcode)
        axios.get(`/api/school/${schoolcode}/questions`)
        .then((response)=>{
            //console.log("Response from backend of questions:",response.data)
            const filteredData = response.data.map((question) => {
                // Filter out options with deleted_at not null
                const filteredOptions = question.survey_options.filter((option) => option.deleted_at === null);
          
                // Check if the question itself is deleted
                if (question.deleted_at === null) {
                  // Include the question if it's not deleted
                  return {
                    ...question,
                    survey_options: filteredOptions,
                  };
                }
                return null; // Exclude the question if it's deleted
              }).filter((item) => item !== null);
          
              setSectionDataArray(filteredData);

            // Initialize the commentBoxOpen state with the same length as sectionDataArray
            setCommentBoxOpen(new Array(response.data.length).fill(false));
            setLoading(false);
        })
        .catch((error)=>{
            ////console.log("Error from backend:",error)
            if(error.response.status === 401){
                ////console.log("401 error")
                navigate('/Error')
            }
        })
    },[data,schoolcode])
    

    const [selectedGrade, setSelectedGrade] = useState(); 
    const options = [
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
    ];

    const handleOptionSelect = (option) => {
        setSelectedGrade(option);
    };

    const handleCommentToggle = (index) => {
        // Create a copy of the commentBoxOpen array
        const updatedCommentBoxOpen = [...commentBoxOpen];
        // Toggle the state of the clicked comment box
        updatedCommentBoxOpen[index] = !updatedCommentBoxOpen[index];
        setCommentBoxOpen(updatedCommentBoxOpen);
    };
    
    const [commentBoxOpen, setCommentBoxOpen] = useState([]);
    
    const handleOptionChange = (questionId,optionText, optionId) => {
      
        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [questionId]: optionId,
        }));
    };
    

    return (
    <div className='signup-page mb-5'>
    {loading ? (
        <LoadingSpinner />
    ) : (
    <div>
      <Container className='mb-5 '>
        <Row className='justify-content-center align-items-center vh-100 ms-md-5 me-md-5 mb-5'>
          <Col xs={12} sm={6} md={7} lg={7}>
            <Card className={`${Styles.customcard} shadow-lg`}>
            <div  className={`${Styles.cardheader} card-header`}>
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center pt-5 img-fluid">
                            <img src={logo} alt="Logo" className={`${Styles.logoimage}`}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center py-4 pt-5 pb-5 mt-5"> {/* Add pt-4 class for top padding */}
                            <h2 className={`${Styles.heading}`}>{translations['SURVEY FORM']}</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
            <>
            {AlertInfo ? (
            <Alert variant={Object.keys(errorMessages).length > 0 ? 'danger' : 'success'} show={Object.keys(errorMessages).length > 0}>
            <p>
            {Object.keys(errorMessages).length > 0 && (
                    <h6 className='text-center'>
                        {errorMessages}
                    </h6>
                    
                )}
            </p>
            </Alert>
            ) : null}
            </>
            <Card.Body className={`${Styles.cardbodywithbackground} ps-md-4 pe-md-4 pt-5 shadow-lg rounded`}>
                <Form className='StudentSurveyform'>
                    <Form.Label>{translations['Enter Your Grade']}</Form.Label>
                    <Dropdown  className={`${Styles.dropdownblock} dropdown-block full-width-dropdown`}>
                    <Dropdown.Toggle className={`${Styles.dropdowntogglelarge} dropdown-toggle-large`}> 
                    {selectedGrade ? selectedGrade.label : translations['Enter Your Grade']}
                    </Dropdown.Toggle>
                        <Dropdown.Menu className={`${Styles.dropdownmenu} dropdown-menu dropdown-menu-scrollable`}>
                        <Dropdown.Divider />
                        {options.map((option) => (
                            <Dropdown.Item key={option.value} onClick={() => handleOptionSelect(option)}>
                            {option.label}
                            </Dropdown.Item>
                        ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form>
                <div>
                    {sectionDataArray.map((sectionData, index) => (
                    <div key={index}>
                   <SurveySection
                        key={sectionData.id}
                        title={sectionData.question_title}
                        options={sectionData.survey_options}
                        defineLabel={"Prefer to Define"}
                        handleCommentToggle={() => handleCommentToggle(index)}
                        handleOptionChange={(optionId,optionText) =>
                            handleOptionChange(sectionData.id,optionText, optionId)
                        }
                        selectedOptionId={selectedValues[sectionData.id]}
                        selectedLanguage={selectedLanguage} // Pass the selected language
                        surveyFormObj={surveyFormObj}
                        commentBoxOpen={commentBoxOpen[index]}

                    />
                    {commentBoxOpen[index] && (
                        <div className="width">
                        <textarea
                            style={{ width: '100%' }}
                            className={`comment`}
                            rows="5"
                            id={`comment-${sectionData.id}`}
                            name={`d_${sectionData.id}`}
                            value={commentsData[sectionData.id]?.text || ''}
                            onChange={(e) => updateComment(sectionData.id, e.target.value)} // Update commentsData when the user types in the textarea
                        />
                        </div>
                    )}
                    </div>
                ))}
                </div>
                {/*Add the button for submit the data form */}
                <div className='d-flex justify-content-center'>
                    <Button variant=""  type='submit' className={`${Styles.btnsignup} shadow-lg`} onClick={handleSubmit}>{translations['SUBMIT']}</Button>
                </div>
            </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    )}
    </div>
  );
}
export default StudentSurveyForm;
