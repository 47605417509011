
import GenericService from "./GenericService";

const SchoolServices = {
    addSchool: (formData) => {
      return GenericService.post("/api/schools/register", formData);
    },
    allSchools:()=>{
      return GenericService.get('/api/schools')
    }
   
};
export default SchoolServices;