export async function translateErrors(texts, source, targetLanguage) {
    // console.log("Text for translation in survey form!", texts);
    // console.log("Source lang:",source)
    //Returns original texts or array in case of source is en
    if (targetLanguage === 'en') {
      return Array.isArray(texts) ? texts : [texts];
    }
    else{
      try {
          let API_KEY = ['AIzaSyB25youL9WVINWAgLi1uWRkNWkKGKxB-AY'];
        if (!Array.isArray(texts)) {
          texts = [texts]; // Convert a single sentence to an array for consistency
        }
    
        const translations = await Promise.all(
          texts.map((text) => {
              let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
              url += `&q=${encodeURI(text)}`;
              url += `&source=${source}`;
              url += `&target=${targetLanguage}`;
        
              return fetch(url, {
                method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json"
                }
              })
              .then((res) => res.json())
              .then((response) => {
                console.log("Response from Google:", response);
                return response.data.translations[0].translatedText;
              });
          })
        );
    
        return translations; // Return an array of translated sentences
      } catch (error) {
        console.error('Translation error:', error);
        return Array.isArray(texts) ? texts : [texts]; // Return the original text(s) in case of an error
      }
    }
  }
  