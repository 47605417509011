import React,{useEffect,useState} from 'react';
import styles from './thankyou.module.css'
import { useNavigate } from 'react-router-dom';
import upperImg from './../../Assets/Thankyou/13.webp';
import {translateContent} from '../../Component/GoogleTranslation/Maptranslation';

function Thankyou() {
    const isvalid=localStorage.getItem('coloredPaths');
    if(!isvalid){
        window.location.href='/';
    }
    const navigate=useNavigate();
    const navigatePage=(e)=>{
        e.preventDefault();
        localStorage.removeItem('coloredPaths');
        localStorage.removeItem('schoolDataInformation');
        localStorage.removeItem('SurveyFormInformation');
        sessionStorage.removeItem('FloorColors');
        sessionStorage.removeItem('Comments');
        localStorage.removeItem('SurveyFormInformation')
        navigate('/')
    }
    
    const selectedLanguage = (window.sessionStorage.getItem("SelectedLanguage") || 'en');
    const [translations, setTranslations] = useState({});
  
    useEffect(() => {
      async function loadTranslations() {
          const elementsToTranslate = [
            'Back to Home',
            'You have completed the activity',
            'THANK YOU'
          ];
  
          const source = selectedLanguage === 'es' ? 'en' : 'es';
  
          try {
          const translatedTexts = await translateContent(elementsToTranslate, source, selectedLanguage);
          setTranslations(translatedTexts);
          } catch (error) {
          console.error('Translation error:', error);
          // Handle the error as needed
          }
      }
  
          loadTranslations();
      }, [selectedLanguage]);
    return(
        <div className={`${styles.container} container-fluid`} id='thankyoupage'>
             <div class="row mb-5">
                <div class="col-12">
                    <img src={upperImg} alt='upper' width="100%" className="img-fluid center-block" />
                </div> 
         </div>
            <div className={`${styles.thankscontent}`}>
                <h2 className={`${styles.thanks}`}>{translations['THANK YOU']}</h2>
                <h3 className='text-center'>{translations['You have completed the activity']}</h3>
                <button type='submit' onClick={navigatePage} className={`${styles.navigateButton}`}>
                    {translations['Back to Home']}
                </button>
            </div>
        </div>
    )
}
export default Thankyou;