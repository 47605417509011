
import GenericService from "./GenericService";

const Agencyservice = {
  addAgency: (formData) => {
    return GenericService.post("/api/agency/register", formData);
  },
  getAgencies: () => {
    return GenericService.get("/api/agencies");
  },
  AgencySignin: (formData) => {
    return GenericService.post("/api/login", formData);
  },
  getAgencySchools: (code) => {
    return GenericService.get(`/api/agency/${code}/schools`);
  },
};
export default Agencyservice;
