
import React,{ Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";
import './App.css';
import SelectSchoolwithAgency from './Pages/SelectSchoolwithAgency';
import SchoolRegistration from './Pages/SchoolRegistration';
import EditSchoolRegistration from './Pages/EditSchool';
import AddSchoolMap from './Pages/AddSchoolMap';
import StudentSurveyForm from './Pages/StudentSurveyForm'
import VideoPlayer from './Pages/Home/videoScript';
import ThankYou from './Pages/ThankyouScreen';
// Lazy load your components
const ResetPassword = lazy(() => import('./Pages/ResetPassword'));
const ForgetPassword = lazy(() => import('./Pages/ForgetPassword'));
const ChangePassword = lazy(() => import('./Pages/ChangePassword'));
const AgencySignUp = lazy(() => import('./Pages/AgencySignUp'));
const AgencySignIn = lazy(() => import('./Pages/AgencySignIn'));
// const SelectSchool = lazy(() => import('./Pages/SelectSchool'));
const StudentSchoolCode = lazy(() => import('./Pages/StudentSchoolCode'));
// const AddSchoolMap = lazy(() => import('./Pages/AddSchoolMap'));
const AgencyWelcomeScreen = lazy(() => import('./Pages/AgencyWelcomeScreen'));
const WeatherChart = lazy(() => import('./Component/Charts/chart'));
//const StudentSurveyForm = lazy(() => import('./Pages/StudentSurveyForm'));
const AddSurveyForm = lazy(() => import('./Pages/AddSurveyForm'));
const SchoolsList = lazy(() => import('./Pages/SchoolsList'));
const AllSchoolsList = lazy(() => import('./Pages/AllSchoolsList'));
const UpdateSchoolsColorsList = lazy(() => import('./Pages/UpdateSchoolsColorsList'));
// const EditSchoolRegistration = lazy(() => import('./Pages/EditSchool'));
const AddFloorColor = lazy(() => import('./Pages/AddFloorColor'));
const Translate = lazy(() => import('./Component/Test/translate'));
const Test = lazy(() => import('./Component/Test/test'));
const GoogleTranslation = lazy(() => import('./Component/Test/GoogleTranslation'));
const TestHighChart = lazy(() => import('./Component/Charts/GradeCharts'));
const SurveyAnalysis = lazy(() => import('./Pages/SurveyAnalysis'));
const MapAnalysis = lazy(() => import('./Pages/MapAnalysis'));
const MapRoom = lazy(() => import('./Pages/MapRoom'));
const LoadingSpinner = lazy(() => import('./Component/ComponentLoader/LoadingSpinner'));
const ErrorScreen = lazy(() => import('./Component/ErrorScreen'));
const NotFoundScreen = lazy(() => import('./Component/NotFoundScreen'));
const Home = lazy(() => import('./Pages/Home'));
const Login = lazy(() => import('./Pages/EducatorLogin'));
const SignUp = lazy(() => import('./Pages/EducatorSignUp'));
const AdminLogin = lazy(() => import('./Pages/AdminLogin'));
const ManageSurveyForm = lazy(() => import('./Pages/ManageSurveyForm'));
const ChangeRoomName = lazy(() => import('./Pages/ChangeRoomName'));
const ChangeFloorRoomName = lazy(() => import('./Pages/ChangeFloorRoomName'));

function RouteComponent() {
  //const isadmin = localStorage.getItem('UserInfo'); // Check if the user is logged in
  const isLoggedIn = localStorage.getItem('token'); // Check if the user is logged in
  const TokenDetails = JSON.parse(localStorage.getItem('UserInfo'));
  const Superadmin = TokenDetails?.role_id === 1 ? true : false;
  //Returns true if token id is 1 otherwise false
  return (
    <>
    <Router>
    <Suspense fallback={<div></div>}>
      <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/educator-signin' element={<Login/>}/>
          <Route path='/educator-signup' element={<SignUp/>}/>
          <Route path='/admin-login' element={<AdminLogin/>}/>
          
          {/*Reset and Forget Password Screen */}

          <Route path='/forgot-password' element={<ForgetPassword/>}/>
          <Route path="/reset_password/:token/:email"  element={<ResetPassword/>}/>
          <Route
          path='/change-password'
          element={isLoggedIn ? <ChangePassword/> : <Navigate to="/Error" />}
          />
          {/* Agency Routes */}
          <Route path='/agency-signup' element={<AgencySignUp/>}/>
          <Route path='/agency-signin' element={<AgencySignIn/>}/>

          {/* School Screens */}
          <Route
          path='/schoolregistration'
          element={Superadmin ? <SchoolRegistration/> : <Navigate to="/Error" />}
          />
          {/* School Screens */}
          <Route
          path='/editSchoolRegistration/:schoolid'
          element={Superadmin ? <EditSchoolRegistration /> : <Navigate to="/Error" />}
          />
          <Route path='/selectSchoolwithagency' element={isLoggedIn ?  <SelectSchoolwithAgency/> : <Navigate to="/" />}/>
          {/* <Route path='/selectSchool' element={<SelectSchool/>}/> */}

          <Route path='/addschoolmap' element={isLoggedIn ?    <AddSchoolMap/> : <Navigate to="/Error" />}/>
          <Route path='/schoolslist' element={isLoggedIn ?  <SchoolsList/>: <Navigate to='/Error' />}/> {/* To show the schools list based on agency code */}
          <Route
          path='/Colorschoolslist'
          element={isLoggedIn ? <UpdateSchoolsColorsList/> : <Navigate to="/Error" />}
          />
          {/* To show the all schools list based on agency code */}
          <Route
          path='/allschoolslist'
          element={Superadmin ? <AllSchoolsList/> : <Navigate to="/Error" />}
          />
          {/**Student Screens */}
          <Route path='/student-schoolcode' element={<StudentSchoolCode/>} />
          <Route path='/student-surveyform' element={<StudentSurveyForm/>} />

          <Route
          path="/welcome-agency"
          element={isLoggedIn ? <AgencyWelcomeScreen /> : <Navigate to="/agency-signin" />}
          />
          <Route path="/addfloorColor" element={<AddFloorColor />} />

          {/*Survey Form */}
          <Route path='/addsurveyform' element={isLoggedIn ? <AddSurveyForm/> : <Navigate to='/Error' />} />

          {/*Manage Survey Form */}
          <Route path='/managesurveyform' element={isLoggedIn ? <ManageSurveyForm/> : <Navigate to='/Error' />} />

          {/*Survey Report */}
          <Route path='/surveyreport' element={isLoggedIn ? <SurveyAnalysis/> : <Navigate to='/Error' />} /> 

          {/*Map Report */}
          <Route path='/mapanalysis' element={isLoggedIn ? <MapAnalysis/> : <Navigate to='/Error' />} />

          {/*Map Room Analysis */}
          <Route path='/maproomanalysis' element={isLoggedIn ? <MapRoom/> : <Navigate to='/' />} />

          {/*Instructional Video route */}
          <Route path='/instruction-video' element={<VideoPlayer/>} />
          {/*Loading Spinner */}
          <Route path='/spinner' element={<LoadingSpinner/>} />

          <Route path='/Error' element={<ErrorScreen />} />
          <Route path='/notfound' element={<NotFoundScreen />} />

          {/*Navigate to not found page */}
          <Route path='*' element={<Navigate to="/notfound" />} />

          {/*Thank you page for showing to students after completing survey form */}
          <Route path='/thankyou' element={<ThankYou/>} /> 

           {/*Test screens */}
           <Route path='/test' element={<Test/>} /> 
          <Route path='/translate' element={<Translate/>} />
          <Route path='/googletranslation' element={<GoogleTranslation/>} />
          <Route path='/Echart' element={<WeatherChart/>} />
          <Route path='/highcharts' element={<TestHighChart/>} />
          
          {/*Change Room Name screen */}
          <Route path='/changeroomname' element={<ChangeRoomName/>} />
          <Route path='/changefloorroomname' element={<ChangeFloorRoomName/>} />
        </Routes>
      </Suspense>
    </Router>
    </>
  );
}

export default RouteComponent;
