import axios from "axios";

axios.defaults.baseURL = "https://ksapbackend.hotspotmapky.com/";
axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`;
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";


const GenericService = {
  get: (url) => {
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  post: (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete: (url) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  put: (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default GenericService;
